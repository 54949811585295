<template>
    <div class="home">
        <div class="home-user">
            <van-row type="flex" justify="center">
                <van-col span="6">
                    <van-image
                        round
                        :src="user.imgUrl"
                    />
                    <van-image
                            v-if="isAdmin"
                            class="home-user-crown"
                            :src="require('@/assets/crown.png')"
                    />
                </van-col>
                <van-col span="12" offset="2">
                    <div class="home-user-info">
                        <div class="home-user-info-name">昵称：{{user.name}}</div>
                        <div>
                            身份：
                            <select class="home-user-info-role" @change="changeRole($event, user.openid)">
                                <option :value ="role.id" v-for="role in roles" :key="role.id" :selected="role.id==user.role">{{role.name}}</option>
                            </select>
                            <van-button type="primary" size="mini" @click="unbind">解绑</van-button>
                        </div>
                    </div>
                </van-col>
            </van-row>
        </div>

        <div class="home-button">
            <van-row type="flex" justify="center">
                <van-col  span="16">
                    <van-button type="primary" size="large" to="family">家庭成员</van-button>
                </van-col>
            </van-row>
            <van-row type="flex" justify="center">
                <van-col  span="16">
                    <van-button type="primary" size="large" to="studyreport">学习报告</van-button>
                </van-col>
            </van-row>
        </div>
    </div>

</template>

<script>
import {Col, Row, Icon, Button, Dialog, Image as VanImage, Toast} from 'vant';
import { changeRole, unBindDevice } from '@/api/wx';
import roles from '@/utils/userRole'
import wx from '../../utils/wxConfig'

export default {
    name: "homepage",
    components: {
        [Col.name]: Col,
        [Row.name]: Row,
        [Icon.name]: Icon,
        [Button.name]: Button,
        [VanImage.name]: VanImage,
        [Dialog.Component.name]: Dialog.Component,
        [Toast.name]: Toast,
    },
    data() {
        return {
            isAdmin: false,
            user: {
                openid: '',
                name: '',
                imgUrl: '',
            },
            deviceId: '',
            roles: roles,
            path: ""
        }
    },
    created() {
        this.initUser();
    },
    async mounted(){
        if (this.deviceId == '') {
            await wx.getConfig()
            this.path = "pages/index/index"
            this.$msgbox({
                title: '提示',
                content: '您还没有绑定设备，前往小程序进行绑定',
                path: this.path
            })
        }
    },
    methods: {
        initUser(){
            this.isAdmin = this.$store.getters.isAdmin;
            this.user = this.$store.getters.user;
            this.deviceId = this.$store.getters.deviceId;
        },
        changeRole(event, openid) {
            changeRole({openid: openid, deviceId: this.deviceId, role: event.target.value}).then(response => {
                if (response.data.code == 0){
                    Toast("设置身份成功")
                } else {
                    Dialog.alert({
                        message: '设置用户身份失败，错误码:'+ response.data.code,
                    }).then(() => {
                        // on close
                    });
                }
            })
        },
        unbind() {
            Dialog.confirm({
                title: '解绑',
                message: this.isAdmin ? '您是管理员，确认要解散所有家庭成员吗？' : '解绑后将不再接收消息，确定解绑吗',
            })
            .then(() => {
                // on confirm
                unBindDevice({openid: this.user.openid, deviceId: this.deviceId}).then(response => {
                    if (response.data.code == 0) {
                        Toast("解绑成功")
                        this.$router.go(0)
                    }else {
                        Dialog.alert({
                            message: '解绑失败，错误码:'+ response.data.code,
                        }).then(() => {
                            // on close
                        });
                    }
                })
            })
            .catch(() => {
                // on cancel
            });
        }
    }
}
</script>

<style lang="less" scoped>
.home {
    &-user {
        padding: 24px 0;
        .van-col{
            position: relative;
        }
        .van-image{
            width: 80px;
            height: 80px;
        }
        &-crown {
            position: absolute;
            transform: translate(-75%, -50%) rotate(30deg);
        }
        &-info {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            font-size: 16px;
            &-name {
                margin-bottom: 8px;
            }
            &-role {
                width: 66px;
                height: 20px;
                font-size: 12px;
            }
            .van-button {
                position: absolute;
                right: 0;
            }
        }
    }

    &-button {
        .van-row {
            padding: 25px 0;
        }
    }
}
</style>
