import wxsdk from 'weixin-js-sdk'
import store from '../store'
import { getSignature } from '@/api/wx';
import { isTicketExpired } from '@/utils/wxAuth'

export default {
    async getConfig() {
        if (store.getters.ticket == null || isTicketExpired()){
            await store.dispatch('wx/getTokenTicket')
        }

        // 获取当前url
        let url = window.location.href.split('#')[0];

        // 获取签名
        await getSignature({ticket:store.getters.ticket, url}).then(res => {
            // 注入配置信息
            wxsdk.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
                appId: res.data.data.appId, // 必填，公众号的唯一标识
                timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
                nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
                signature: res.data.data.signature,// 必填，签名
                jsApiList: ['wx-open-launch-weapp'], // 必填，需要使用的JS接口列表
                openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表
            });
            wxsdk.ready(function () {
                console.log('config is success')
            })
            wxsdk.error(function (err) {
                console.log(err)
            })
        })


    }
}
